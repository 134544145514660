import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

// const dicFormatter = (e)=>{
//     let obj_1 = []
//     for(let item of e){
//         if(item){
//             let obj_ = {
//                 label: item.chain,
//                 value: item.chain,
//             }
//             if(item.chainProtocolInfos){
//                 let obj_c = []
//                 for(let items of item.chainProtocolInfos){
//                     obj_c.push({
//                         label: items.protocol,
//                         value: items.protocol
//                     })
//                 }
//                 obj_.children = obj_c
//                 obj_1.push(obj_)
//             }
//         }
//     }
//     return obj_1
// }
export const seriesList = [{
    type: 'select',
    name: t('currencySetNft.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'select',
    name: t('currencySetNft.name2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],   
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
},{
    type: 'select',
    name: t('currencySetNft.text7'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},{
    type: 'input',
    name: t('nfts.name1'),
    prop: 'contractAddress',
},{
    type: 'input',
    name: t('nfts.text13'),
    prop: 'collectionName',
},{
    type: 'datetime',
    name: t('nfts.name18'),
    prop: 'createTime',
},]
export const coin = [{
    type:'input',
    name:t('nfts.name14'),
    prop: 'contractName'
},{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
},{
    type: 'select',
    name: t('currencySetNft.text7'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},]